// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/images/plus.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".add-list[data-v-305f77fa] {\n  padding: 1.25rem 0 0 1.25rem;\n}\n.add-list li[data-v-305f77fa] {\n  padding: 1.25rem 0;\n}\n.add-list li .left[data-v-305f77fa] {\n  flex-direction: column;\n  flex: 1;\n}\n.add-list li .left .user-info[data-v-305f77fa] {\n  font-size: 1rem;\n  font-family: PingFangSC-Medium, PingFang SC;\n}\n.add-list li .left .user-info p[data-v-305f77fa] {\n  font-weight: 500;\n  color: #232830;\n  margin-right: 0.625rem;\n}\n.add-list li .left .user-info span[data-v-305f77fa] {\n  font-weight: 500;\n  color: #232830;\n  margin-right: 0.25rem;\n}\n.add-list li .left .user-info i[data-v-305f77fa] {\n  display: inline-block;\n  width: 1.75rem;\n  height: 0.875rem;\n  border-radius: 0.125rem;\n  border: 0.0625rem solid #FF6450;\n  font-size: 0.625rem;\n  font-family: PingFangSC-Regular, PingFang SC;\n  font-weight: 400;\n  color: #FF6450;\n  text-align: center;\n  line-height: 0.875rem;\n}\n.add-list li .left .address[data-v-305f77fa] {\n  color: #8B8B8B;\n  font-size: 0.75rem;\n}\n.add-list li .right[data-v-305f77fa] {\n  width: 3.125rem;\n  height: 2.5rem;\n  border-left: 0.0625rem solid #F2F2F2;\n}\n.add-list li .right img[data-v-305f77fa] {\n  width: 0.875rem;\n  height: 0.875rem;\n}\n.hidden[data-v-305f77fa] {\n  height: 3.25rem;\n  background: #fafafa;\n}\n.add-btn[data-v-305f77fa] {\n  width: 100%;\n  height: 3.25rem;\n  background: linear-gradient(136deg, #FF7F4E 0%, #FF6453 100%);\n  color: #FFFFFF;\n  font-size: 1rem;\n  position: fixed;\n  bottom: 0;\n}\n.add-btn .plus-icon[data-v-305f77fa] {\n  width: 0.75rem;\n  height: 0.75rem;\n  display: inline-block;\n  background-size: 100%;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n}\n", ""]);
// Exports
module.exports = exports;
