var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticStyle: { background: "#FFFFFF" } }, [
    _vm.addressList.length
      ? _c(
          "ul",
          { staticClass: "add-list" },
          _vm._l(_vm.addressList, function (item, index) {
            return _c("li", { key: index, staticClass: "flex f-jc-sb" }, [
              _c(
                "div",
                {
                  staticClass: "left flex",
                  on: {
                    click: function ($event) {
                      return _vm.clickItem(item)
                    },
                  },
                },
                [
                  _c("div", { staticClass: "user-info flex f-ai-c" }, [
                    _c("p", [_vm._v(_vm._s(item.name))]),
                    _c("span", [_vm._v(_vm._s(item.mobilePhone))]),
                    item.isDefault ? _c("i", [_vm._v("默认")]) : _vm._e(),
                  ]),
                  _c("span", { staticClass: "address" }, [
                    _vm._v(_vm._s(item.address)),
                  ]),
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "right flex f-jc-c f-ai-c",
                  on: {
                    click: function ($event) {
                      return _vm.clickHandle(item.id)
                    },
                  },
                },
                [
                  _c("img", {
                    attrs: {
                      src: require("@/assets/images/edit-btn.png"),
                      alt: "",
                    },
                  }),
                ]
              ),
            ])
          }),
          0
        )
      : _vm._e(),
    _vm.addressList.length ? _c("div", { staticClass: "hidden" }) : _vm._e(),
    _c(
      "div",
      {
        staticClass: "add-btn flex f-jc-c f-ai-c",
        on: {
          click: function ($event) {
            $event.preventDefault()
            return _vm.addAddress.apply(null, arguments)
          },
        },
      },
      [_vm._m(0)]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", [
      _c("i", { staticClass: "plus-icon" }),
      _vm._v(" 新建收货地址 "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }