<template>
	<div style="background: #FFFFFF;">
		<ul class="add-list" v-if="addressList.length">
			<li v-for="(item, index) in addressList" :key="index" class="flex f-jc-sb">
				<div class="left flex" @click="clickItem(item)">
					<div class="user-info flex f-ai-c">
						<p>{{item.name}}</p>
						<span>{{item.mobilePhone}}</span>
						<i v-if="item.isDefault">默认</i>
					</div>
					<span class="address">{{item.address}}</span>
				</div>
				<div class="right flex f-jc-c f-ai-c" @click="clickHandle(item.id)">
					<img src="@/assets/images/edit-btn.png" alt="">
				</div>
			</li>
		</ul>
		<div v-if="addressList.length" class="hidden"></div>
		<div class="add-btn flex f-jc-c f-ai-c" @click.prevent="addAddress">
			<span>
				<i class="plus-icon"></i>
				新建收货地址
			</span>
		</div>
	</div>
</template>
<script>
import { deliList } from '@/request/api/user';

export default {
	data () {
		return {
			addressList: []
		};
	},
	created () {
		this.getDeliList();
	},
	methods: {
		clickItem (item) {
			this.$store.commit('setAddressInfo', { ...item });
			this.$root.replace({
				path: 'fillin-order',
				query: { ...this.$route.query, isChanged: true }
			});
		},
		clickHandle (id) {
			this.$root.go('da-opt', { id });
		},
		addAddress () {
			this.$root.go('da-opt');
		},
		getDeliList () {
			deliList().then(data => {
				if (data && data === 'retry') {
					this.getDeliList();
				} else if (data) {
					this.addressList = data;
				}
			}).catch(e => {
				this.$root.elMsg(e.message, 'fail');
			});
		}
	}
};
</script>
<style lang="less" scoped>
	.add-list {
		padding: 20px 0 0 20px;
		li {
			padding: 20px 0;
			.left {
				flex-direction: column;
				flex: 1;
				.user-info {
					font-size: 16px;
					font-family: PingFangSC-Medium, PingFang SC;
					p {
						font-weight: 500;
						color: #232830;
						margin-right: 10px;
					}
					span {
						font-weight: 500;
						color: #232830;
						margin-right: 4px;
					}
					i {
						display: inline-block;
						width: 28px;
						height: 14px;
						border-radius: 2px;
						border: 1px solid #FF6450;
						font-size: 10px;
						font-family: PingFangSC-Regular, PingFang SC;
						font-weight: 400;
						color: #FF6450;
						text-align: center;
						line-height: 14px;
					}
				}
				.address {
					color: #8B8B8B;
					font-size: 12px;
				}
			}
			.right {
				width: 50px;
				height: 40px;
				border-left: 1px solid #F2F2F2;
				img {
					width: 14px;
					height: 14px;
				}
			}
		}
	}
	.hidden {
		height: 52px;
		background: #fafafa;
	}
	.add-btn {
		width: 100%;
		height: 52px;
		background:  linear-gradient(136deg, #FF7F4E 0%, #FF6453 100%);
		color: #FFFFFF;
		font-size: 16px;
		position: fixed;
		bottom: 0;
		.plus-icon {
			width: 12px;
			height: 12px;
			display: inline-block;
			background-size: 100%;
			background-image: url('../../assets/images/plus.png');
		}
	}
</style>